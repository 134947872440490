import FundUnicefKrTemplate from '@/page-blocks/about-us/clear/fund/unicef-kr/FundUnicefKrTemplate';
import ReportKorea2023 from '@/page-blocks/about-us/clear/fund/unicef-kr/report.korea_2023';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicefKr2023: React.FC<PageProps> = (props) => (
  <FundUnicefKrTemplate year={2023} {...props}>
    <ReportKorea2023 />
  </FundUnicefKrTemplate>
);

export default FundUnicefKr2023;
